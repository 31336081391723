import Vue from 'vue';
import MembersList from '../../vue/MembersList.vue';

if(document.querySelector('#members-list')) {
  const membersList = new Vue({
    el: 'members-list',

    components: {
      MembersList
    }
  })

}
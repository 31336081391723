var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mb-14 md:mb-16 lg:mb-24" }, [
    _c("div", { staticClass: "content-wrapper content-wrapper--xlarge" }, [
      _c("div", { staticClass: "mx-auto xs:w-10/12 lg:w-8/12" }, [
        _c(
          "div",
          { staticClass: "py-3-5 px-4 sm:px-8 md:pb-17 max-w-md mx-auto" },
          [
            _c("multiselect", {
              attrs: {
                options: _vm.options,
                noResult: _vm.noResult,
                showLabels: false,
                noOptions: _vm.noOptions,
                tagPlaceholder: _vm.tagPlaceholder,
                placeholder: _vm.placeholder,
                multiple: true,
                trackBy: "id",
                label: "title",
                taggable: true,
              },
              on: { tag: _vm.addTag },
              model: {
                value: _vm.selectedOptions,
                callback: function ($$v) {
                  _vm.selectedOptions = $$v
                },
                expression: "selectedOptions",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.items, function (item) {
            return _c("li", { staticClass: "odd:bg-grey" }, [
              _c(
                "div",
                {
                  staticClass:
                    "font-sans-book leading-none pl-3-5 sm:flex sm:flex-wrap sm:items-center",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "mr-auto ml-0 text-black text-base py-3-5 pr-3-5 block hover:opacity-50",
                      attrs: { href: item.externalLink, target: "_blank" },
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mx-0 mt-3-5 sm:mt-0 py-3-5" },
                    _vm._l(item.memberCategory, function (cat) {
                      return _c(
                        "button",
                        {
                          staticClass:
                            "text-black text-xxs border border-gray-100 px-1-5 py-4px mr-3-5 bg-white hover:bg-blue hover:text-white",
                          on: {
                            click: function ($event) {
                              return _vm.fetchByCategory(cat)
                            },
                          },
                        },
                        [_vm._v(_vm._s(cat.title))]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.pagination.total_pages > 1
          ? _c(
              "div",
              {
                staticClass:
                  "pagination px-4 mt-8 lg:mt-16 default-text text-black",
              },
              [
                _c("paginate", {
                  attrs: {
                    "page-count": _vm.pagination.total_pages,
                    "prev-text": _vm.prevText,
                    "next-text": _vm.nextText,
                    clickHandler: _vm.updatePagination,
                    "container-class": "pagination-list",
                    "page-class": "pagination-list__item",
                    "page-link-class": "pagination-list__item__link",
                    "prev-class": "pagination-list__prev",
                    "prev-link-class": "pagination-list__prev__link",
                    "next-class": "pagination-list__next",
                    "next-link-class": "pagination-list__next__link",
                    "break-view-class": "pagination-list__break",
                    "break-view-link-class": "pagination-list__break__link",
                    "active-class": "is-current",
                    "disabled-class": "is-disabled",
                  },
                  model: {
                    value: _vm.pagination.current_page,
                    callback: function ($$v) {
                      _vm.$set(_vm.pagination, "current_page", $$v)
                    },
                    expression: "pagination.current_page",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="mb-14 md:mb-16 lg:mb-24">
    <div class="content-wrapper content-wrapper--xlarge">
      <div class="mx-auto xs:w-10/12 lg:w-8/12">
        <div class="py-3-5 px-4 sm:px-8 md:pb-17 max-w-md mx-auto">
          <multiselect
            :options="options"
            :noResult="noResult"
            :showLabels="false"
            :noOptions="noOptions"
            :tagPlaceholder="tagPlaceholder"
            :placeholder="placeholder"
            :multiple="true"
            trackBy="id"
            label="title"
            :taggable="true"
            @tag="addTag"
            v-model="selectedOptions"
          ></multiselect>
        </div>
        <ul>
          <li class="odd:bg-grey" v-for="item in items">
            <div class="font-sans-book leading-none pl-3-5 sm:flex sm:flex-wrap sm:items-center">
              <a :href="item.externalLink" target="_blank" class="mr-auto ml-0 text-black text-base py-3-5 pr-3-5 block hover:opacity-50">{{ item.title }}</a>
              <div class="mx-0 mt-3-5 sm:mt-0 py-3-5">
                <button class="text-black text-xxs border border-gray-100 px-1-5 py-4px mr-3-5 bg-white hover:bg-blue hover:text-white" v-for="cat in item.memberCategory" v-on:click="fetchByCategory(cat)">{{ cat.title }}</button>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="pagination.total_pages > 1" class="pagination px-4 mt-8 lg:mt-16 default-text text-black">
          <paginate
            v-model="pagination.current_page"
            :page-count="pagination.total_pages"
            :prev-text="prevText"
            :next-text="nextText"
            :clickHandler="updatePagination"
            container-class="pagination-list"
            page-class="pagination-list__item"
            page-link-class="pagination-list__item__link"
            prev-class="pagination-list__prev"
            prev-link-class="pagination-list__prev__link"
            next-class="pagination-list__next"
            next-link-class="pagination-list__next__link"
            break-view-class="pagination-list__break"
            break-view-link-class="pagination-list__break__link"
            active-class="is-current"
            disabled-class="is-disabled"
          >
          </paginate>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import axios from 'axios';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import Paginate from 'vuejs-paginate';

let query = window.location.search.substring(1);
export function getQueryParam(param) {
  if (query.length === 0) {
    return false;
  }
  let params = decodeURIComponent(query).split('&');
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      return decodeURIComponent(pair[1]);
    }
  }
  return false;
}

export default {
  props: {
    placeholder: {
      type: String,
      defult: '',
    },
    noResult: {
      type: String,
      defult: '',
    },
    noOptions: {
      type: String,
      defult: '',
    },
    tagPlaceholder: {
      type: String,
      defult: '',
    },
    prevText: {
      type: String,
      defult: '',
    },
    nextText: {
      type: String,
      defult: '',
    }
  },

  components: {
    Multiselect,
    Paginate
  },
  data() {
    return {
      selectedOptions: [],
      categoriesLoading: true,
      categories: [],
      options: [],
      items: [],
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 0,
        total_pages: 0,
        links: {},
      },
      isLoading: false,
    }
  },

  created() {
    this.fetchAllCategories();
    this.fetchItems();

    // Set memberCategories on page load
    let memberCategories = getQueryParam('categories');
    if(memberCategories.length) {
      this.categories = memberCategories;
    }

    // Set memberSearch on page load
    let setSearch = getQueryParam('search');
    if(setSearch.length) {
      this.search = setSearch;
    }

    // Set Pagination on page load
    // let setCurrentPage = getQueryParam('page');
    // if(setCurrentPage.length) {
    //   //this.paginate.results.page = setCurrentPage - 1;
    // }
  },

  watch: {
    selectedOptions: function(val, oldVal) {
      // Fetch new results
      this.fetchItems();
    },
  },

  methods: {
    setHistory: function() {

      // let self = this,
      //     params = '?';

      // if(self.categories.length && (self.categories != 'all')) {
      //   params += '&category=' + self.categories;
      // }

      // if(self.paginate.results.page !== 0) {
      //   params += '&page=' + (self.paginate.results.page + 1);
      // }

      // if(self.search.length) {
      //   params += '&search=' + self.search;
      // }

      // if(window.history && window.history.replaceState) {
      //   history.replaceState(null, null, params);

      //   return params;
      // }
    },

    fetchByCategory: function(category) {
      let self = this;
      let selected = self.selectedOptions;
      let params = {};

      if (_.includes(self.selectedOptions, category)) {
        // Remove from selectedOptions
        selected = _.filter(selected, function(item) {
            if (item.id !== category.id) {
              return item;
            }
        });
      } else {
        // add to chosendCategories
        selected.push(category);
      }

      // update selectedOptions
      self.selectedOptions = selected;
    },

    fetchAllCategories: function() {

      let self = this;

      self.categoriesLoading = true;

      axios.get('/api/members/categories')
      .then(function (response) {
        // handle success
        self.categories = response.data.data;
        self.options = response.data.data;
        //Vue.set(self, 'items', filteredItems);
        self.categoriesLoading = false;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    },

    fetchItems: function() {
      let self = this;

      if (self.isLoading) {
        return false;
      }

      self.isLoading = true;

      axios({
        method: 'GET',
        url: '/api/members',
        params: self.parseOptions(),
      })
      .then(function (response) {
        // handle success
        self.items = response.data.data;
        self.pagination = response.data.meta.pagination;

        //Vue.set(self, 'items', filteredItems);
        self.isLoading = false;
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    },

    addTag: function(newTag) {
      const tag = {
        title: newTag,
        slug: newTag,
        id: newTag,
        type: 'search',
      };

      this.selectedOptions.push(tag)
    },

    parseOptions: function() {
      let options = {};
      let selectedOptions = this.selectedOptions;
      let categories = [];
      let search = '';

      selectedOptions.forEach(item => {
        if (item.type == 'category') {
          categories.push(item.id);
        }
        if (item.type == 'search') {
          search = search + ' ' + item.title;
        }
      });

      if (categories.length) {
        options.categories = categories;
      }

      if (search.length) {
        options.search = search;
      }

      options.page = this.pagination.current_page;

      return options;
    },

    updatePagination: function(page) {
      this.fetchItems();
    }
  }
}
</script>
